import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import Logo from "../../assets/images/logo.svg";
import { useUser } from "../../context/userContext";
import ActiveLink from "./ActiveLink";

const Navbar = () => {
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const router = useRouter();
  const { t } = useTranslation("common");

  useEffect(() => {
    router.events.on("routeChangeComplete", onClose);

    return () => router.events.off("routeChangeComplete", onClose);
  }, [router, onClose]);

  return (
    <Container
      maxW="container.2xl"
      position="fixed"
      bgColor="white"
      borderBottom="1px solid lightgrey"
      zIndex="3"
    >
      <Box py={{ base: 6, sm: 6, lg: 6 }} px={{ base: 2, sm: 6, lg: 10 }}>
        <Flex
          position="relative"
          justifyContent="space-between"
          height="16"
          alignItems="center"
        >
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={16} alignItems={"center"}>
            <Box>
              <ActiveLink href="/">
                <Logo />
              </ActiveLink>
            </Box>
            <HStack
              as={"nav"}
              spacing={12}
              display={{ base: "none", md: "flex" }}
            >
              <ActiveLink href="/dashboard" aria-current="page">
                {t("navbar.dashboard")}
              </ActiveLink>
              <ActiveLink href="/pricing">{t("navbar.pricing")}</ActiveLink>
              <ActiveLink href="/support">{t("navbar.support")}</ActiveLink>
            </HStack>
          </HStack>
          <Flex alignItems="center">
            {!user && (
              <HStack display={{ base: "none", sm: "none", md: "flex" }}>
                <div className="text-gray-800 hover:text-black px-3 py-2 rounded-md text-md font-medium">
                  <ActiveLink href="/signin">Log in</ActiveLink>
                </div>
                <div className="ml-3">
                  <Button
                    variant="solid"
                    size="md"
                    width="100%"
                    borderRadius={3}
                    display="flex"
                    backgroundColor="pink.500"
                    _hover={{
                      backgroundColor: "pink.400",
                    }}
                    color="white"
                  >
                    <ActiveLink href="/signup">Sign Up</ActiveLink>
                  </Button>
                </div>
              </HStack>
            )}

            <div className="ml-3 relative">
              <button
                type="button"
                className="p-1 rounded-full text-gray-400 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  width="54"
                  height="54"
                  viewBox="0 0 36 20"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
              </button>
            </div>
            <div className="ml-3 relative">
              <Menu placement="bottom-end" isLazy>
                <MenuButton
                  transition="all 0.2s"
                  className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  {user && <Avatar size={"sm"} src={user?.avatar_url} />}
                </MenuButton>
                <MenuList>
                  <MenuItem>
                    <ActiveLink href="/profil">Your Profile</ActiveLink>
                  </MenuItem>
                  <MenuItem>
                    <ActiveLink href="/settings">Settings</ActiveLink>
                  </MenuItem>
                  <MenuItem>
                    <ActiveLink href="/signout">Sign out</ActiveLink>
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </Flex>
        </Flex>
      </Box>
      {isOpen ? (
        <Box pb={4} pl="6" display={{ md: "none", xs: "none" }}>
          <Stack as={"nav"} spacing={4}>
            <ActiveLink href="/dashboard">{t("navbar.dashboard")}</ActiveLink>
            <ActiveLink href="/pricing">{t("navbar.pricing")}</ActiveLink>
            <ActiveLink href="/support">{t("navbar.support")}</ActiveLink>
            {!user && (
              <>
                <ActiveLink href="/signin">Log in</ActiveLink>
                <ActiveLink href="/signup">Sign up</ActiveLink>
              </>
            )}
            {user && (
              <>
                <ActiveLink href="/signout">Sign out</ActiveLink>
              </>
            )}
          </Stack>
        </Box>
      ) : null}
    </Container>
  );
};

export default Navbar;
