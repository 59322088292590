import { apiClient } from "@internals/utils/api/apiClient";

export const setCookie = (data: any) =>
  apiClient()
    .post("/api/auth", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
