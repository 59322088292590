const Input = {
  variants: {
    outline: {
      field: {
        border: "2px solid",
        borderColor: "gray.900",
      },
    },
  },
};

export default Input;
