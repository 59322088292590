import { Provider, Session, User } from '@supabase/supabase-js'
import { createContext, useContext, useEffect, useState } from 'react'

import { SignInOptions, SignUpOptions, Subscription, UserDetails } from '../types'
import { supabase } from '../utils/supabaseClient'
import { setCookie } from '@internals/utils/api/auth'

type UserContextType = {
  session: Session
  setSession: (session: Session | null) => void
  user: UserDetails
  setUser: (user: UserDetails | null) => void
  subscription: Subscription
  setSupabaseCookies: () => void
  signIn: (options: SignInOptions) => Promise<{
    session: Session | null
    user: User | null
    provider?: Provider
    url?: string | null
    error: Error | null
    data: Session | null
  }>
  signUp: (options: SignUpOptions) => Promise<{
    user: User | null
    session: Session | null
    provider?: Provider
    error: Error | null
    data: Session | User | null
  }>
  signOut: () => void
  resetPassword: (email: string) => Promise<{ error: Error | null; data: Session | User | null }>
}

export const UserContext = createContext<UserContextType | undefined>(undefined)

export const UserContextProvider = (props: any) => {
  const [session, setSession] = useState<Session | null>(null)
  const [user, setUser] = useState<User | null>(null)
  const [subscription, setSubscription] = useState<Subscription | null>(null)

  const value = {
    session,
    setSession,
    user,
    subscription,
    setUser,
    signIn: (options: SignInOptions) => supabase.auth.signIn(options),
    signUp: (options: SignUpOptions) => {
      return supabase.auth.signUp(options)
    },
    signOut: () => {
      setSession(null)
      setUser(null)
      supabase.auth.signOut()
      document.location.href = '/signin'
    },
    resetPassword: () => (email: string) => supabase.auth.api.resetPasswordForEmail(email),
  }

  useEffect(() => {
    const session = supabase.auth.session()
    setSession(session)
    setUser(session?.user ?? null)
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      setCookie({ event, session })
      setSession(session)
      setUser(session?.user ?? null)
    })

    return () => {
      authListener?.unsubscribe()
    }
  }, [])

  const getUserDetails = () => supabase.from<UserDetails>('users').select('*').single()
  const getSubscription = () =>
    supabase
      .from<Subscription>('subscriptions')
      .select('*, prices(*, products(*))')
      .in('status', ['trialing', 'active'])
      .single()

  useEffect(() => {
    if (user) {
      Promise.allSettled([getUserDetails(), getSubscription()]).then((results) => {
        const userDetailsPromise = results?.[0]
        const subscriptionPromise = results?.[1]

        if (subscriptionPromise.status === 'fulfilled') {
          setSubscription(subscriptionPromise?.value?.data)
          setUser({
            ...user,
            ...subscriptionPromise?.value?.data,
          })
        }

        if (userDetailsPromise.status === 'fulfilled') {
          // setUser({
          //   ...user,
          //   ...userDetailsPromise.value.data,
          // });
          console.log('launch')
        }
      })
    }
  }, [user?.id])

  return <UserContext.Provider value={value} {...props} />
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`)
  }
  return context
}
