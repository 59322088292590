import {
  Box,
  Container,
  Flex,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import Logo from '../../assets/images/logo_reverse.svg';
import ActiveLink from '@internals/components/Footer/ActiveLink';

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithLogoCentered() {
  return (
    <Box
      borderTop="1px solid"
      borderColor={'gray.200'}
      bgColor={'gray.900'}
      color="gray.50"
    >
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
          <Stack align={'flex-start'}>
            <ListHeader>Use Discern</ListHeader>
            <ActiveLink href={'#'}>Overview</ActiveLink>
            <Stack direction={'row'} align={'center'} spacing={2}>
              <ActiveLink href={'#'}>Features</ActiveLink>
              <Tag size={'sm'} bg={'green.300'} ml={2} color={'white'}>
                New
              </Tag>
            </Stack>
            <ActiveLink href={'#'}>Tutorials</ActiveLink>
            <ActiveLink href={'#'}>Pricing</ActiveLink>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Company</ListHeader>
            <ActiveLink href={'#'}>About Us</ActiveLink>
            <ActiveLink href={'/support'}>Contact Us</ActiveLink>
            <ActiveLink href={'#'}>Partners</ActiveLink>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Legal</ListHeader>
            <ActiveLink href={'#'}>Cookies Policy</ActiveLink>
            <ActiveLink href={'#'}>Privacy Policy</ActiveLink>
            <ActiveLink href={'#'}>Terms of Use</ActiveLink>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Follow Us</ListHeader>
            <ActiveLink href={'#'}>Facebook</ActiveLink>
            <ActiveLink href={'#'}>Twitter</ActiveLink>
            <ActiveLink href={'#'}>LinkedIn</ActiveLink>
          </Stack>
        </SimpleGrid>
      </Container>
      <Box py={10}>
        <Flex
          align={'center'}
          _before={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Logo />
        </Flex>
        <Text pt={6} fontSize={'sm'} textAlign={'center'}>
          © 2021 Discern. All rights reserved
        </Text>
      </Box>
    </Box>
  );
}
