import axios from "axios";

export const apiClient = () => {
  const baseUrl = process.env.BASE_URL || "";

  const options: any = {
    baseUrl: baseUrl,
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      credentials: "same-origin",
    },
  };

  const instance = axios.create(options);

  instance.interceptors.request.use((request) => {
    return { ...request };
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => error
  );

  return instance;
};
