const Textarea = {
  variants: {
    outline: {
      border: "2px solid",
      borderColor: "gray.900",
    },
  },
};

export default Textarea;
