const Button = {
  variants: {
    outline: {
      border: "2px solid",
      borderColor: "gray.900",
      backgroundColor: "transparent",
    },
  },
};

export default Button;
