import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

type Props = {
  children: React.ReactNode
  href: string
}

function ActiveLink({ children, href }: Props) {
  const router = useRouter()
  const activeStyle = {
    fontWeight: router.asPath === href ? 'bold' : 'normal',
  }

  return (
    <Link href={href}>
      <span style={activeStyle}>{children}</span>
    </Link>
  )
}

export default ActiveLink
