import { Container } from '@chakra-ui/react'
import Script from 'next/script'
import React, { ReactNode } from 'react'

import { UserContextProvider } from '../../context/userContext'
import Footer from '../Footer'
import Navbar from '../Navbar'

interface Props {
  children: ReactNode
}

const Layout = ({ children }: Props) => {
  return (
    <UserContextProvider>
      <Navbar />
      <Container as="main" maxW={'7xl'} p="12" pt="110">
        {children}
      </Container>
      <Footer />
      <Script
        id="crisp-chat"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.$crisp=[];window.CRISP_WEBSITE_ID="9acdc212-42dd-4c75-bfbc-67c76341e078";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
          `,
        }}
      />
      {/* <Script
        id="smartlook"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.smartlook||(function(d) {
              var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
              var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
              c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
              })(document);
              smartlook('init', '6790b0e17a376e6a87fccf2003a2101618aa9eee', { region: 'eu' });
          `,
        }}
      /> */}
    </UserContextProvider>
  )
}

export default Layout
