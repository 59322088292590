import { extendTheme } from "@chakra-ui/react";
import Button from "./Button";
import Input from "./Input";
import Link from "./Link";
import Textarea from "./Textarea";

const theme = extendTheme({
  fullHeight: "calc(100vh - 115px)",
  colors: {
    pink: {
      "50": "#FFE5EF",
      "100": "#FFB8D1",
      "200": "#FF8AB4",
      "300": "#FF5C96",
      "400": "#FF2E79",
      "500": "#FF005B",
      "600": "#CC0049",
      "700": "#990037",
      "800": "#660025",
      "900": "#330012",
    },
    blue: {
      "50": "#E6E5FF",
      "100": "#B9B8FF",
      "200": "#8C8AFF",
      "300": "#605CFF",
      "400": "#332EFF",
      "500": "#0600FF",
      "600": "#0500CC",
      "700": "#040099",
      "800": "#020066",
      "900": "#010033",
    },
    gray: {
      "50": "#F2F2F2",
      "100": "#DBDBDB",
      "200": "#C4C4C4",
      "300": "#ADADAD",
      "400": "#969696",
      "500": "#808080",
      "600": "#666666",
      "700": "#4D4D4D",
      "800": "#333333",
      "900": "#1A1A1A",
    },
  },
  components: {
    Button,
    Link,
    Input,
    Textarea,
  },
});

export default theme;
