
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { ChakraProvider } from '@chakra-ui/react'
import amplitude from 'amplitude-js'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'

import Layout from '../components/Layout'
import '../styles/globals.css'
import theme from '../theme'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  }
})

function MyApp({ Component, pageProps }: AppProps) {
  const { asPath } = useRouter()

  amplitude.getInstance().init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY || '')

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        {
          asPath.includes('/projects/') ? (
            <Component {...pageProps} />
          ) : (
            <Layout>
              <Component {...pageProps} />
            </Layout>
          )
        }
      </ChakraProvider>
    </QueryClientProvider>
  )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  